import React from 'react';

import Typography from '@mui/material/Typography';

const HomeFinancesView = () => {
    return (
        <>
            <Typography variant="h1">
                Home Finances !!
            </Typography>
        </>
    )
}

export default HomeFinancesView;
