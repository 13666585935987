import React from 'react';

const TheBoysNetworkView = () => {
    return (
         <>
             The boys newtwork
         </>
    );
}

export default TheBoysNetworkView;
