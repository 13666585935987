import React from 'react';

const IdeasByBadge = () => {
    return (
        
            <div className='page-body'>
                Ideas by badge
            </div>
    );
}

export default IdeasByBadge;