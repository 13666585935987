import React from 'react';

import Link from '@mui/material/Link';

const ProjectsView = () => {
    return (
        <>
            <Link href="/Projects/HomeFinances">Home Finances</Link>
            <Link href="/Projects/achievements">Achievements data driven Demo</Link>
        </>
    )
}

export default ProjectsView;