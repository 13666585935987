import React from "react";

const AboutMe = () => {
    return (
        <div>
            About Me in Scouting
        </div>
    );
}

export default AboutMe;